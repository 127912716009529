import { Button, Modal } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import { BANNERS_SRC_URL } from '../../../config/webservice';
import { useAuth } from '../../../contexts/authContext';
import { Role } from '../../../constants/role';
import userHasRoles from '../../../utils/userHasRoles';

interface AdAlertModalProps {
    show: boolean;
    onHide: () => void;
    banners: Array<{ filename: string; format: string; id: number }>;
}

export default function AdAlertModal({
    show,
    onHide,
    banners,
}: AdAlertModalProps) {
    const { user } = useAuth();

    const isAdministrator = userHasRoles(user, [Role.Administrator]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            backdrop={isAdministrator ? true : 'static'}
            keyboard={isAdministrator}
        >
            <Carousel
                showStatus={false}
                showThumbs={false}
                autoPlay
                infiniteLoop
            >
                {banners?.map((item: any) =>
                    item?.referLink ? (
                        <a
                            href={item.referLink.toString()}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div key={item?.id}>
                                <img
                                    src={`${BANNERS_SRC_URL}/${item?.fileName}.${item?.format}`}
                                    alt=""
                                />
                            </div>
                        </a>
                    ) : (
                        <div key={item?.id}>
                            <img
                                src={`${BANNERS_SRC_URL}/${item?.fileName}.${item?.format}`}
                                alt=""
                            />
                        </div>
                    ),
                )}
            </Carousel>

            <Modal.Footer>
                {isAdministrator && (
                    <Button
                        onClick={onHide}
                        style={{ width: '134px' }}
                        variant="outline-primary"
                        size="sm"
                    >
                        Fechar
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}
